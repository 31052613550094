import { DocumentsListComponent } from './../../popups/documents-list/documents-list.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { _getURL } from 'config/API_CONFIG';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { ImageService } from 'src/app/services/image/image.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuppressionComponent } from 'src/app/popups/suppression/suppression.component';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-consultation-list',
  templateUrl: './consultation-list.component.html',
  styleUrls: ['./consultation-list.component.css']
})
export class ConsultationListComponent implements OnInit {
  page: number = 1;
  tableSize: number = 4;
  tableSizes: any = [3, 6, 9, 12];
  items: any[] = []; // Your data items
  displayedItems: any[] = []; // Items to display on the current page
  count: number = 0;
  documents: any = [];
  medecin: any
  medecinId: any
  patient: any
  imagePatient: any
  urlImage: any
  listFiles: any
  pathImage = _getURL("") + "data/files/imagePatient/"
  apci = [
    { 'id': '8', 'name': 'Affections coronariennes et leurs complications' },
    { 'id': '3', 'name': 'Affections hypophysaires' },
    { 'id': '4', 'name': 'Affections surrénaliennes' },
    { 'id': '6', 'name': 'Cardiopathies congénitales et valvulopathies' },
    { 'id': '22', 'name': 'Cirrhoses et insuffisance hépatique' },
    { 'id': '1', 'name': 'Diabète insulinodépendant ou non insulinodépendant' },
    { 'id': '2', 'name': 'Dysthyroïdies' },
    { 'id': '13', 'name': 'Epilepsie' },
    { 'id': '23', 'name': 'Glaucome' },
    { 'id': '5', 'name': 'HTA sévère' },
    { 'id': '21', 'name': 'Hépatites chroniques actives' },
    { 'id': '7', 'name': 'Insuffisance cardiaque et troubles du rythme' },
    { 'id': '11', 'name': 'Insuffisance respiratoire chronique' },
    { 'id': '16', 'name': 'Insuffisance rénale chronique' },
    { 'id': '20', 'name': 'Les maladies inflammatoires chroniques de l’intestin' },
    { 'id': '14', 'name': 'Maladie de Parkinson' },
    { 'id': '18', 'name': 'Maladies auto-immunes' },
    { 'id': '24', 'name': 'Mucoviscidose' },
    { 'id': '9', 'name': 'Phlébites' },
    { 'id': '15', 'name': 'Psychoses et névroses' },
    { 'id': '17', 'name': 'Rhumatismes inflammatoires chroniques' },
    { 'id': '12', 'name': 'Sclérose en plaques' },
    { 'id': '10', 'name': 'Tuberculose active' },
    { 'id': '19', 'name': 'Tumeurs et hémopathies malignes' }
  ];
  p: number = 1
  idPatient: any
  copyOfAllDocuments: any

  constructor(
    private activatedRoute: ActivatedRoute, 
    private authService: AuthService, 
    private router: Router, 
    private patientService: PatientService, 
    private imageService: ImageService, 
    private documentService: DocumentService,
    private modalService: NgbModal,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.loadPatientId();
    this.loadMedecinProfile();
    this.loadPatientImage();
  }

  loadPatientId(): void {
    const params: any = this.activatedRoute.snapshot.params;
    this.idPatient = params.id;
  }

  loadMedecinProfile(): void {
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe(
        (profile: any) => {
          this.medecin = profile.medecin;
          if (!this.medecin.roles.gestionPatient) {
            this.router.navigate(['/notFound']);
            return false;
          }
          this.medecinId = this.medecin._id;
          this.getOtherDataForMedecin();
          this.getAllDocuments();
        },
        err => {
          console.log(err);
          return false;
        }
      );
    }
  }

  loadPatientImage(): void {
    this.imageService.getImagePatient(this.idPatient).subscribe(
      profile => {
        this.imagePatient = profile[0];
        if (this.imagePatient) {
          this.urlImage = "https://test.medivisto.com/uploads/patient/" + this.imagePatient.name;
        }
      },
      err => {
        console.log(err);
        return false;
      }
    );
  }

  getPatientInfo(value: any): string {
    return value ? value : 'Aucun';
  }

  getOtherDataForMedecin() {
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }

  getNameById(id: string): string {
    const match = this.apci.find(item => item.id === id);
    return match ? match.name : id;
  }

  supprimerDocument(id) {

    const modalRef = this.modalService.open(SuppressionComponent);
    modalRef.componentInstance.patient = id;
    modalRef.componentInstance.name = "document";
    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.deleteDocument(id);
      }
    }, (reason) => {
      // Handle dismiss case
    });
  }

  deleteDocument(id) {
    this.documentService.deletePatientDocuement(id).subscribe((data: any) => {
      this.toastService.showSuccess("Bien supprimé");
      this.getAllDocuments()
    })
  }

  getAllDocuments() {
    // get all documents for patient
    if (this.idPatient) {
      this.documentService.getDocumentsObecjtsPatient(this.idPatient, this.medecinId).subscribe((data: any) => {
        this.documents = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        this.documents.map(el => { el.date = new Date(el.date).toLocaleString('en-GB'); el.createdAt = new Date(el.createdAt).toLocaleString('en-GB') })
        this.items = data;
        this.copyOfAllDocuments = this.documents
        // Check if the current page needs to be adjusted
        const totalItems = this.items.length;
        const totalPages = Math.ceil(totalItems / this.tableSize);
        if (this.page > totalPages) {
          this.page = totalPages > 0 ? totalPages : 1;
        }
        this.loadItems();
      })
      return
    } else {
      return false
    }
  }
  
  onTableDataChange(event: any) {
    this.page = event;
    this.loadItems(); // Method to load the items for the current page
  }

  loadItems(): void {
    // Calculate the start and end index for items on the current page
    const startIndex = (this.page - 1) * this.tableSize;
    const endIndex = startIndex + this.tableSize;
    const pageItems = this.items.slice(startIndex, endIndex);

    // If there are no items on the current page, return to page 1
    if (pageItems.length === 0 && this.page > 1) {
      this.page = 1;
      this.loadItems();
    } else {
      // Otherwise, update the displayed items
      this.displayedItems = pageItems;
    }
  }

  getListFilesNames(id_doc) {
    this.documentService.getFileNamesSharedBypatient(id_doc, this.idPatient).subscribe((data: any) => {
      this.listFiles = data.files

      const modalRef = this.modalService.open(DocumentsListComponent);
      modalRef.componentInstance.sharedFiles = this.listFiles;
      modalRef.componentInstance.document = data.document;
      modalRef.componentInstance.idPatient = this.idPatient;

    }, (error) => console.log(error))
  }

  openListFichiers(files: any[]) {
    const modalRef = this.modalService.open(DocumentsListComponent);
    modalRef.componentInstance.files = files;
  }

  bloquerAcces(file) {
    this.documentService.onUpdateDocuementPatient(file).subscribe((res: any) => {
      this.toastService.showSuccess("Bien modifié");
    })
  }

  searchDocument(event: string) {
    if (!event) {
      this.documents = this.copyOfAllDocuments;
    } else {
      this.page = 1;
      const searchTerm = event.toLocaleLowerCase();
      this.documents = this.copyOfAllDocuments.filter(el => {
        return el.type?.toLocaleLowerCase().includes(searchTerm) ||
          el.createdAt?.toLocaleLowerCase().includes(searchTerm) ||
          el.date?.toLocaleLowerCase().includes(searchTerm) ||
          el.titre?.toLocaleLowerCase().includes(searchTerm) ||
          el.statut?.toLocaleLowerCase().includes(searchTerm) ||
          (el.medecin?.nom?.toLocaleLowerCase().includes(searchTerm) ||
            el.medecin?.prenom?.toLocaleLowerCase().includes(searchTerm)) ||
          (!el.medecin && el.patient?.nom?.toLocaleLowerCase().includes(searchTerm) ||
            !el.medecin && el.patient?.prenom?.toLocaleLowerCase().includes(searchTerm));
      });
    }
  }
}
