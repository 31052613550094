<div class="select-container" [ngStyle]="selectContainer">
  <h3 *ngIf="label">{{ label }}</h3>
  <ng-select
    [items]="items"
    bindLabel="name"
    bindValue="id"
    [(ngModel)]="selectedItems"
    (ngModelChange)="selectedItemsChange.emit($event)"
    [multiple]="multiple"
    [searchable]="true"
    [ngStyle]="ngSelectStyle"
    [placeholder]="placeholder">

    <!-- Custom template to hide selected items inside the input -->
      <ng-template ng-label-tmp let-item="item" *ngIf="multiple">
          <!-- Empty template to hide selected items -->
      </ng-template>
  </ng-select>

  <div class="selected-items" *ngIf="selectedItems?.length" [ngStyle]="selectedItemsStyle">
    <div class="selected-item" *ngFor="let item of selectedItemObjects; let i = index">
      {{ item.name }}
      <button (click)="removeSelectedItem(i)">&times;</button>
    </div>
  </div>

  <!-- Display single selected item when multiple is false -->
  <div class="selected-item-single" *ngIf="!multiple && selectedItems?.length">
    {{ selectedItemObjects[0]?.name }}
    <button (click)="clearAll()">&times;</button>
  </div>
</div>