import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anteced-med',
  templateUrl: './anteced-med.component.html',
  styleUrls: ['./anteced-med.component.css']
})
export class AntecedMedComponent implements OnInit {

  selectedPerso: any[] = [];
  selectedFamil: any[] = [];
  selectedTraitements: any[] = [];
  selectedChirurgies: any[] = [];
  selectedTabagism: any[] = [];
  selectedConsommation: any[] = [];
  selectedActivities: any[] = [];
  selectedHabitudes: any[] = [];

  perso = [
    { id: 1, name: 'Hypertension' },
    { id: 2, name: 'Diabète' },
    { id: 3, name: 'Asthme' },
    { id: 4, name: 'Maladies Cardiaques' },
    { id: 5, name: 'Troubles Thyroïdiens' },
    { id: 6, name: 'Allergies' }
  ];

  famil = [
    { id: 1, name: 'Maladies Cardiovasculaires' },
    { id: 2, name: 'Cancer' },
    { id: 3, name: 'Diabète' },
    { id: 4, name: 'Troubles Génétiques' },
  ];

  traitements = [
    { id: 1, name: 'Antibiotiques' },
    { id: 2, name: 'Antalgiques' },
    { id: 3, name: 'Antihypertenseurs' },
    { id: 4, name: 'Insuline' },
  ];

  chirurgies = [
    { id: 1, name: 'Appendicectomie' },
    { id: 2, name: 'Cure de Hernie' },
    { id: 3, name: 'Césarienne' },
  ]

  tabagism = [
    { id: 1, name: 'Jamais' },
    { id: 2, name: 'Ancien Fumeur' },
    { id: 3, name: 'Fumeur Actuel' },
  ]

  consommation = [
    { id: 1, name: 'Jamais' },
    { id: 2, name: 'Occasionnelle' },
    { id: 3, name: 'Régulière' },
  ]

  activities = [
    { id: 1, name: 'Sédentaire' },
    { id: 2, name: 'Modérée' },
    { id: 3, name: 'Active' },
  ]

  habitudes = [
    { id: 1, name: 'Végétarien' },
    { id: 2, name: 'Non-Végétarien' },
    { id: 3, name: 'Végan' },
  ]


  constructor() { }

  ngOnInit(): void {
  }
}
