<div class="container-fluid content-container">

  <div class="row">
    <div class="card form-card">
      <div class="card-body">
        <b class="form-item-menu">
          Antécédents Chirurgicaux
        </b>
        <div class="card-content mt-3">
          <div class="select-item">
            <label class="form-title">
              Générales
            </label>
            <app-reusable-ngselect
              [items]="chirurgies"
              [(selectedItems)]="selectedChirurgies"
              placholder="Select personal antecendents"
              [ngClass]="{ 'custom-width': true }">
            </app-reusable-ngselect>
          </div>
        </div>
      </div>
    </div>
    <div class="card form-card">
      <div class="card-body">
        <b class="form-item-menu">
          Traitements
        </b>
        <div class="card-content mt-3">
          <div class="select-item">
            <label class="form-title">
              En cours
            </label>
            <app-reusable-ngselect
              [items]="traitements"
              [(selectedItems)]="selectedTraitements"
              placholder="Select personal antecendents"
              [ngClass]="{ 'custom-width': true }">
            </app-reusable-ngselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="card form-card">
      <div class="card-body">
        <b class="form-item-menu">
          Antécédents médicaux
        </b>
        <div class="card-content mt-3">
          <div class="select-item">
            <label class="form-title">
              Personnels
            </label>
            <app-reusable-ngselect
              [items]="perso"
              [(selectedItems)]="selectedPerso"
              placholder="Select personal antecendents">
            </app-reusable-ngselect>
          </div>
          <div class="select-item">
            <label class="form-title">
              Familiaux
            </label>
            <app-reusable-ngselect
              [items]="famil"
              [(selectedItems)]="selectedFamil"
              placeholder="Select family antecedents">
            </app-reusable-ngselect>
          </div>
        </div>
      </div>
    </div>
    <div class="card form-card">
      <div class="card-body">
        <b class="form-item-menu">
          Mode de Vie et Habitudes
        </b>
        <div class="card-content mt-3">
          <div class="select-item">
            <label class="form-title">
              Tabagisme
            </label>
            <app-reusable-ngselect
              [items]="tabagism"
              [(selectedItems)]="selectedTabagism"
              [multiple]="false"
              placholder="Select personal antecendents">
            </app-reusable-ngselect>
          </div>
          <div class="select-item">
            <label class="form-title">
              Consommation d'Alcool
            </label>
            <app-reusable-ngselect
              [items]="consommation"
              [(selectedItems)]="selectedConsommation"
              [multiple]="false"
              placeholder="Select family antecedents">
            </app-reusable-ngselect>
          </div>
        </div>
        <div class="card-content mt-2">
          <div class="select-item">
            <label class="form-title">
              Activité Physique
            </label>
            <app-reusable-ngselect
              [items]="activities"
              [(selectedItems)]="selectedActivities"
              [multiple]="false"
              placholder="Select personal antecendents">
            </app-reusable-ngselect>
          </div>
          <div class="select-item">
            <label class="form-title">
              Habitudes Alimentaires
            </label>
            <app-reusable-ngselect
              [items]="habitudes"
              [(selectedItems)]="selectedHabitudes"
              [multiple]="false"
              placeholder="Select family antecedents">
            </app-reusable-ngselect>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>