<div class="row details-container">
    <!-- First Card: User Details -->
    <div class="col-12 col-md-6 col-lg-4 mb-4">
        <div class="card h-100"> <!-- Ensure the card takes full height of its container -->
            <div class="card-body box1 d-flex flex-column flex-sm-row align-items-center p-3"> <!-- Add padding and flex layout -->
                <!-- Avatar Container -->
                <div class="avatar-container text-center mb-3 mb-sm-0"> <!-- Add margin-bottom for small screens -->
                    <img src="assets/images/doctor-avatar.jpg" alt="User Avatar" class="avatar"> <!-- Ensure avatar is responsive -->
                </div>
                <!-- Details Table -->
                <div class="details-card flex-grow-1 ms-sm-3"> <!-- Allow table to grow and add margin for small screens -->
                    <table class="table table-borderless table-details m-0"> <!-- Remove default margin -->
                        <tbody>
                            <tr>
                                <td><b>Nom</b></td>
                                <td class="text-right">
                                    <span>{{ patientDetails.nom }}</span>
                                    <span class="badge badge-secondary" *ngIf="!patientDetails.nom">Aucun</span>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Age</b></td>
                                <td class="text-right">
                                    <span>{{ patientDetails.age }}</span>
                                    <span class="badge badge-secondary" *ngIf="!patientDetails.age">Aucun</span>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Date de Naissance</b></td>
                                <td class="text-right">
                                    <span>{{ patientDetails.dateNaissance }}</span>
                                    <span class="badge badge-secondary" *ngIf="!patientDetails.dateNaissance">Aucun</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Second Card: Motifs -->
    <div class="col-12 col-md-6 col-lg-4 mb-4">
        <div class="card h-100">
            <div class="card-body box2 d-flex flex-column">
                <label class="form-title mb-3">
                    Motifs
                </label>
                <app-reusable-ngselect
                    [items]="motifs"
                    [(selectedItems)]="selectedMotifs"
                    placeholder="Select Motifs"
                    [selectContainer]="{
                        'min-width': '100%'
                    }"
                    [ngSelectStyle]="{
                        'width': '100%',
                        'max-width': '100%'
                    }"
                    [selectedItemsStyle]="{
                        'width': '100%'
                    }">
                </app-reusable-ngselect>
            </div>
        </div>
    </div>

    <!-- Third Card: Visits and History -->
    <div class="col-12 col-md-6 col-lg-2 mb-4">
        <div class="card h-100">
            <div class="card-body box3 d-flex flex-column justify-content-evenly">
                <div class="details-data">
                    <b class="titles text-start nbr-vis">Nombre des visites</b>
                    <span class="badge badge-number">3</span>
                </div>
                <div class="btn-history text-center">
                    <label class="btn-history-label d-block mb-2 d-none">Historique des consultations</label>
                    <button class="btn btn-custom" (click)="historiqueConsultationModal()">
                        <i class="fa-solid fa-clock-rotate-left"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Fourth Card: Timer -->
    <div class="col-12 col-md-6 col-lg-2 mb-4">
        <div class="card h-100">
            <div class="card-body box4 d-flex flex-column justify-content-between align-items-center">
                <i class="fa-solid fa-stopwatch fa-2x mb-3"></i>
                <div class="details-data text-center">
                    <b class="titles">Durée:</b>
                    <span>00:00</span>
                </div>
                <div class="controls d-flex justify-content-around w-100">
                    <div class="pause-icon">
                        <button class="btn-duree" id="pauseBtn"><i class="fa-solid fa-pause"></i></button>
                    </div>
                    <div class="stop-icon">
                        <button class="btn-duree" id="stopBtn"><i class="fa-solid fa-stop"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>