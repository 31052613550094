<div class="sidebar">
  <ul class="sidebar-links">
    <!-- Interrogatoire Parent -->
    <li class="link-item">
      <a 
        (click)="navigateToInterrogatoire()"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.active]="isActiveLink('interrogatoire')">
        <i class="fa-solid fa-file-circle-question mr-2"></i>
        Interrogatoire
      </a>
      <ul class="sub-links" *ngIf="isActiveLink('interrogatoire')">
        <li>
          <a 
            [routerLink]="['/medecin/examen/interrogatoire/antecedents-medicaux']"
            [routerLinkActive]="['active']" 
            [routerLinkActiveOptions]="{ exact: false }">Antécédents Médicaux</a>
        </li>
        <li>
          <a 
            [routerLink]="['/medecin/examen/interrogatoire/interrogatoire-systematique']"
            [routerLinkActive]="['active']" 
            [routerLinkActiveOptions]="{ exact: false }">Interrogatoire Systématique</a>
        </li>
      </ul>      
    </li>

    <!-- Examen Parent -->
    <li class="link-item">
      <a 
        [routerLink]="['/medecin/examen/exam']"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.active]="isActiveLink('exam')">
        <i class="fa-solid fa-stethoscope mr-2"></i>
        Examen
      </a>      
    </li>

    <!-- Conduite à tenir Parent -->
    <li class="link-item">
      <a 
        [routerLink]="['/medecin/examen/conclusion']"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.active]="isActiveLink('conclusion')">
        <i class="fa-solid fa-file-waveform mr-2"></i>
        Conclusion
      </a>      
    </li>

    <!-- Conduite à tenir Parent -->
    <li class="link-item">
      <a 
        [routerLink]="['/medecin/examen/conduite-tenir']"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.active]="isActiveLink('conduite-tenir')">
        <i class="fa-solid fa-notes-medical mr-2"></i>
        Conduite à tenir
      </a>      
    </li>

    <!-- Documents Parent -->
    <li class="link-item">
      <a 
        [routerLink]="['/medecin/examen/documents']"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }"
        [class.active]="isActiveLink('documents')">
        <i class="fa-solid fa-file-medical mr-2"></i>
        Documents
      </a>      
    </li>
  </ul>

  <div class="suivi-consultation">
    <hr>
    <div class="buttons">
      <!-- <button class="btn btn-primary" (click)="openOffCanvas()"><i class="fa-solid fa-scroll"></i></button> -->

      <!-- <app-off-canvas></app-off-canvas> -->
    </div>
  </div>


</div>
