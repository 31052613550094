<div class="modal-header">
    <h5 class="mb-0">Historique des consultations</h5>
    <a class="exit" aria-label="Close" (click)="onReset()">
      <i class="fa-regular fa-circle-xmark"></i>
    </a>
</div>

<div class="modal-body">
    <hr class="split-btns" />
    <div class="buttons">
        <button class="btn btn-secondary mr-2" (click)="onReset()">Fermer</button>
    </div>
</div>