<app-header></app-header>
<div class="container-fluid p-sm-5 bg-light ">
  <div class="row">
    <div class="col-lg-12">
      <ul class="breadcrumb float-md-right">
        <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/accueil"><i class="fa-solid fa-house"></i>
            Accueil</a>
        </li>
        <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/patients-liste">
            Patients</a>
        </li>
        <li class="breadcrumb-item active">Détails patient</li>
      </ul>
    </div>
  </div>
  <div class="container-fluid bg-light" *ngIf="patient">

    <section>
      <section class="mt-4">
        <div class="row">
          <div class="col-9">
            <div class="row pat">
              <div class="col-5">
                <b>{{patient.nom}} {{patient.prenom}}</b>
              </div>

              <div class="col-4 mediv">
                <b> {{patient.idPatient}}</b>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row buttons-container justify-content-end">
              <div class="button">
                <div class="buttoncadre" *ngIf="patient.createdByDoctor">
                  <a [routerLink]="['/medecin/patient', patient._id]" title="Modifier un patient">
                    <i class="fa-regular fa-pen-to-square mt-2"></i>
                  </a>
                </div>
              </div>
              <div class="button">
                <div class="buttoncadre" *ngIf="medecin">
                  <a title="Historique des consultations">
                    <i class="fa-solid fa-clock-rotate-left mt-2"></i>
                  </a>
                </div>
              </div>
              <div class="button">
                <div class="buttoncadre" *ngIf="medecin">
                  <a title="Ajouter une consultation" data-toggle="modal" data-target="#myModalExamen">
                    <i class="fa-solid fa-file-circle-plus mt-2" title="Ajouter une consultation"></i>
                  </a>
                </div>
              </div>

              <!-- modal ajout motif de consultation -->
              <!-- <div class="modal fade" id="myModalExamen" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content modal-content-scan">
                        <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
                          <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                            Motif de consultation
                          </h4>
                          <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                            Modifier la consultation
                          </h4>
                          <a class="exit" aria-label="Close" data-dismiss="modal" (click)="deleteSelectedMotif($event)"><i class="fa-regular fa-circle-xmark"></i></a>
                        </div>
                        <div class="modal-body">
                          <div>
                            <form [formGroup]="formMotif">
                              <div class="form-row">
                                <label style="font-size: 14.5px;position: relative;top: 4px; " *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">Motif(s)</label>
                                <div class="form-group col">
                                  <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                                    <div class="col-12">
                                      <input type="text" name="type" list="typename" style="border: 1px solid #bdbdbd;" class="form-control input-sm inputMotif" [(ngModel)]="selectedMotif" (change)="addInputMotif($event)" formControlName="motif" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                                      <datalist id="typename">
                                        <option *ngFor="let motif of motifList" [value]="motif">
                                      </datalist>
                                    </div>
                                    <div *ngIf="formMotif.controls.motif?.invalid&& (formMotif.controls.motif?.dirty || formMotif.controls.motif?.touched)&& formMotif.controls.motif?.errors  " class="text-center">
                                      <div *ngIf=" formMotif.controls.motif?.errors.required && selectedMotifs.length===0" style="color: red;font-size: 15px;">Le motif est obligatoire</div>
                                      <div *ngIf="formMotif.controls.motif?.errors.minlength" style="color: red;font-size: 15px;">{{motifMinLength}}</div>
                                      <div *ngIf="formMotif.controls.motif?.errors.maxlength" style="color: red;font-size: 15px;">{{motifMaxLength}}</div>
                                      <div *ngIf="formMotif.controls.motif?.errors.invalidMotif" style="color: red;font-size: 15px;">{{champSpecialCaracters}}</div>
                                    </div>
                                    <div style="text-align: center;"><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;cursor: pointer;" (click)="removeMotif(mot)">X
                                        </div> <br></span></div>
                                  </div>
                                  <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                                    <p id="consultationencours" style="font-size: 14.6px;text-align: center;">Vous avez déjà
                                      enregistré une consultation pour ce patient
                                      aujourd'hui. <br> Voulez-vous la modifier ?
                                    </p>
                                  </div>
                                </div>
                              </div>
      
                              <div class="d-flex justify-content-center mt-4">
                
                                <div class="text-center"> 
                                  <button class="btn btn-secondary mr-2" (click)="deleteSelectedMotif($event)">Annuler</button>
                                  <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1" (click)="checkFormAndSave(patient)">Sauvegarder</button>
                                  <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0" (click)="geToUpdateConsultation()">Modifier</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
              <!-- modal ajout motif de consultation  -->
            </div>
          </div>
        </div>

        <div class="informationPat2">
          <!-- <img src="assets/images/patsDetails/pat1.png" class="imgMed"> -->
          <i class="fa-solid fa-user mt-3 user-logo" style="font-size: 50px; color:#21726B ;"></i>
          <!-- <img [src]="pathImage + patient.image" *ngIf="patient.image" class="imgMed" style="border-radius: 36px;height: 80px;"> -->
        </div>
        <div class="informationPat">
          <div class="card numFiche-card">
            <div class="card-body">
              <div class="fiche">
                <b class="ficheNum-label">N° Fiche : </b> <b class="ficheNum-value">{{patient.numFiche}}</b>
              </div>
            </div>
          </div>

          <div class="row informationsPat">
            <div class="col-4">
              <div class="card details-card">
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td><b>Date de naissance :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.dateAnniversaire; else noDate">{{ patient.dateAnniversaire | date: 'dd/MM/yyyy' }}</span>
                          <ng-template #noDate>
                            <span class="badge badge-secondary">Aucun</span>
                          </ng-template>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Age :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.age">{{ patient.age + ' ans' }}</span>
                          <span *ngIf="!patient.age" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Sexe :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.sexe">{{ patient.sexe }}</span>
                          <span *ngIf="!patient.sexe" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Groupe Sanguin :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.groupeSanguin">{{ patient.groupeSanguin }}</span>
                          <span *ngIf="!patient.groupeSanguin" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          
            <div class="col-4">
              <div class="card details-card">
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td><b>Statut Familial :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.statusFamilial">{{ patient.statusFamilial }}</span>
                          <span *ngIf="!patient.statusFamilial" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Profession :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.profession">{{ patient.profession }}</span>
                          <span *ngIf="!patient.profession" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr>
                        <td><b>N° Mobile:</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.tel">{{ patient.tel }}</span>
                          <span *ngIf="!patient.tel" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr>
                        <td><b>2<sup>ème</sup> N° Mobile :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.telsec">{{ patient.telsec }}</span>
                          <span *ngIf="!patient.telsec" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          
            <div class="col-4">
              <div class="card details-card">
                <div class="card-body">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td><b>Email :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.email">{{ patient.email }}</span>
                          <span *ngIf="!patient.email" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Adresse :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.adresse.adresse || patient.adresse.codePostal || patient.adresse.ville">{{ patient.adresse.adresse + ', ' + patient.adresse.codePostal + ' ' + patient.adresse.ville }}</span>
                          <span *ngIf="!patient.adresse.adresse && !patient.adresse.codePostal && !patient.adresse.ville" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr>
                        <td><b>APCI :</b></td>
                        <td  *ngIf="patient.apci && patient.apci.length > 0" class="text-left">
                          <div>
                            <ul>
                              <li *ngFor="let ap of patient.apci">{{ getNameById(ap) }}</li>
                            </ul>
                          </div>
                        </td>
                        <td *ngIf="patient.apci.length === 0" class="text-right">
                          <span class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr *ngIf="patient.orgSante.typeOrg == 'CNAM'">
                        <td><b>Code CNAM :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.orgSante.codeCNAM">{{ patient.orgSante.codeCNAM }}</span>
                          <span *ngIf="!patient.orgSante.codeCNAM" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr *ngIf="patient.orgSante.typeOrg == 'CNSS'">
                        <td><b>Code CNSS :</b></td>
                        <td class="text-right">
                          <span *ngIf="patient.orgSante.codeCNSS">{{ patient.orgSante.codeCNSS }}</span>
                          <span *ngIf="!patient.orgSante.codeCNSS" class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                      <tr *ngIf="!patient.orgSante.typeOrg">
                        <td><b>Type d'org</b></td>
                        <td class="text-right">
                          <span class="badge badge-secondary">Aucun</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="patient.notes" class="row informationsPat notes-container">
            <div class="card notes-card">
              <div class="card-body">
                <div class="notes">
                  <b class="notes-label">Notes : </b> <b class="notes-value">{{patient.notes}}</b>
                </div>
              </div>
            </div>
          </div>
          <br> <br>
        </div>
        <!---------------------------- Liste Visites effectuées ---------->
        <div class="" #historiqueExamen>
        </div>
        <div class="row mt-3">
          <div class="col-9"> </div>
          <div class="col-3 ">
            <div class="row buttons-container justify-content-end">
              <div class="button">
                <div class="buttoncadre" *ngIf="medecin">
                  <a [routerLink]="['/medecin/ajout/nouveaux/documents/', patient._id]" title="Ajouter documents">
                    <i class="fa-solid fa-upload "></i>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="informationPat3" *ngIf="medecin">
          <div class="row bar ">
            <div class="col-3"><a class="linkPart2"><b>Documents</b></a>
              <div class="input-group  mt-3" *ngIf="copyOfAllDocuments && copyOfAllDocuments.length > 0">
                <input type="text" class="form-control" placeholder="Rechercher par Type, Date, Titre, Statut..." aria-label="Document search" aria-describedby="Document search" (input)="searchDocument($event.target.value)">
              </div>
            </div>

            <div *ngIf="documents && documents.length == 0" class="table-responsive" style="text-align: center;color: #666;">
              <p style="padding-bottom: 5rem; padding-top: 5rem ">
                Aucun document n'est trouvé
              </p>
            </div>
            <table class="table mt-2" *ngIf="documents && documents.length > 0">
              <thead>
                <tr class="ligne-paire text-center">
                  <th scope="col" style="color:#589590;">Type</th>
                  <th scope="col" style="color:#589590;">Titre</th>
                  <th scope="col" style="color:#589590;">Ajouté le</th>
                  <th scope="col" style="color:#589590;">Date</th>
                  <th scope="col" style="color:#589590;">Auteur</th>
                  <th scope="col" style="color:#589590;">Statut</th>
                  <th scope="col" style="color:#589590;">Accès</th>
                  <th scope="col" style="color:#589590;">Actions</th>
                </tr>
              </thead>
              <tbody *ngFor="let i of documents | paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                  }; let k = index">
                <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center">
                  <td>{{i.type}}</td>
                  <td>{{i.titre}}</td>
                  <td>{{i.createdAt }}</td>
                  <td>{{i.date }}</td>
                  <td *ngIf="!i.medecin || i.medecin==null">
                    {{i.patient?.nom}} {{i.patient?.prenom}}
                  </td>
                  <td *ngIf="i.medecin">
                    {{i.medecin?.nom}} {{i.medecin?.prenom}}
                  </td>
                  <td>{{i.statut}}</td>
                  <td>
                    <label class="switch ml-2" *ngIf="i.medecin">
                      <input type="checkbox" [(ngModel)]="i.autoriserAcces" (value)="!i.autoriserAcces" (change)="bloquerAcces(i)">
                      <span class="slider round"></span>
                    </label>
                  </td>
                  <td>
                    <div>
                      <a *ngIf="i.medecin" (click)="openListFichiers(i.documents)"><i class="fa-regular fa-eye mr-1" title="Liste des fichiers"></i></a>
                      <a *ngIf="!i.medecin" (click)="getListFilesNames(i._id)"><i class="fa-regular fa-eye mr-1" title="Liste des fichiers"></i></a>
                      <a *ngIf="i.medecin" title="Modifier documents" [routerLink]="['/medecin/document/patient/modifier', idPatient,i._id]">
                        <i class="fa-regular fa-pen-to-square mr-1"></i>
                      </a>
                      <a *ngIf="i.medecin" (click)="supprimerDocument(i._id)"><i class="fa-solid fa-trash" title="Supprimer document"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <div *ngIf="documents && documents.length > tableSize " class=" border-0 mt-4 mb-2">
            <div class="d-flex justify-content-center">
              <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
              </pagination-controls>
            </div>
          </div>
          <!-- <div class="row rightButts">
                        <div class="col-1 c1" style=" margin-right: 82%;">
                            <div class=" buttoncadre0">
                                <div class="row">
                                    <div class="col-2">
                                        <img class="iconButt2 " src="assets/images/patsDetails/search_3.png" style="visibility: hidden">
                                    </div>
                                    <div class="col-6">
                                        <input type="text" name="name" id="recherche" class="recherche" placeholder="Rechercher ... " style="visibility: hidden" [(ngModel)]="search" name="search">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <!-- <div class="div-tab2">
                        <div class="row" style="overflow-x:auto;">
                            <table class="table  ">
                                <thead>
                                    <th id="firstThTable" scope="col" style="color:#589590;text-align: center;"><b>DATE</b></th>
                                    <th scope="col" style="color:#589590;text-align: center;"><b>MOTIF</b></th>
                                    <th scope="col" style="color:#589590;text-align: center;"><b>DURÉE</b></th>
                                    <th scope="col" style="color:#589590;width: 20%;margin-right: 26px;text-align: center;" *ngIf="medecin"><b>PARAMÈTRES</b></th>
                                </thead> -->
          <!-- <tbody *ngFor="let consultation of allExamnes| paginate: { itemsPerPage: 25, currentPage: p, id: 'p'  }|searching : search.toString().toLowerCase();let k=index"> -->
          <!-- <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center">
                        <td>{{consultation.start | date: 'dd/MM/yyyy, HH:mm'}}</td>
                        <td><span *ngFor="let mot of consultation.motif">{{mot}}<br></span></td>
                        <td>{{consultation.duration}}</td>
                        <td style="border-bottom-style: none;" *ngIf="medecin">
                          <a [routerLink]="['/medecin/ajout/interrogatoire',patient._id, consultation.id]" title="Modifier consultation"><i class="fa-solid fa-pencil mr-1"></i></a>
                          <a><i class="fa-solid fa-trash" (click)="selectedConsultationFunction(consultation)" title="Supprimer" data-toggle="modal" data-target="#myModal9"></i></a>
                        </td> -->
          <!---------------------------- Pop up supprimer VISITE ---------->
          <!-- <div class="modal fade" id="myModal9" tabindex="-1" role="dialog" aria-labelledby="myModal9" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content modal-content-scan">
                              <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
                                <h4 class="modal-title">
                                  Suppression d'un Examen
                                </h4>
                                <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
                              </div>
                              <div class="modal-body">
                                <div class="row" style="margin-left: 6%;">
                                  <label style="font-size: 14.6px;text-align: center;">Voulez-vous vraiment supprimer cet examen?</label>
                                </div>
                                <div class="d-flex justify-content-center mt-4">
                              
                                  <div class="text-center">
                                    <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>
                                    <button class="btn btn-primary" data-dismiss="modal" (click)="deleteconsultation(consultation)">Confirmer</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
          <!-- modal suppression VISITE -->

          <!-- </tr> -->
          <!-- </tbody> -->
          <!-- </table>
                        </div>
                    </div> -->
          <!-- <div class="pagination2">
                <div class="row justify-content-center">
                  <div class="col-3 " style="padding-top: 10px; padding-left: 35px">
                    <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event" id="p"></pagination-controls>
                  </div>
                </div>
              </div> -->
        </div>
      </section>

    </section>
  </div>

</div>


<app-footer></app-footer>