import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-examen-sidebar',
  templateUrl: './examen-sidebar.component.html',
  styleUrls: ['./examen-sidebar.component.css']
})
export class ExamenSidebarComponent implements OnInit {
  
  currentRoute: string;

  constructor(
    private router: Router,
    // private offcanvas: OffCanvasComponent
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.urlAfterRedirects;
    });
  }

  isActiveLink(path: string): boolean {
    const basePathInterrogatoire = '/medecin/examen/interrogatoire';

  
    const currentRoute = this.currentRoute || '';
  
    if (currentRoute.startsWith(basePathInterrogatoire) && path === 'interrogatoire') {
      return true;
    }
  
    // Re add this condition for another component if he will have child components
  
    return false;
  }

  navigateToInterrogatoire(): void {
    this.router.navigate(['/medecin/examen/interrogatoire/antecedents-medicaux']);
  }

  // openOffCanvas() {
  //   this.offcanvas.open();
  // }
  
}