import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reusable-ngselect',
  templateUrl: './reusable-ngselect.component.html',
  styleUrls: ['./reusable-ngselect.component.css']
})
export class ReusableNgselectComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() selectedItems: any[] = [];
  @Input() placeholder: string = 'Sélectionner les options';
  @Input() label: string = '';
  @Input() multiple: boolean = true;
  @Input() ngSelectStyle: any = {};
  @Input() selectedItemsStyle: any = {};
  @Input() selectContainer: any = {};
  
  @Output() selectedItemsChange = new EventEmitter<any[]>();

  constructor() { }

  ngOnInit(): void {
  }

  get selectedItemObjects() {
    return this.selectedItems.map(id =>
      this.items.find(item => item.id === id)
    ).filter(item => !!item);
  }

  onSelectionChange(selectedItems: any[]) {
    if (!this.multiple) {
      this.selectedItems = selectedItems.length > 0 ? [selectedItems[0]] : [];
    } else {
      this.selectedItems = selectedItems;
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }


  removeSelectedItem(index: number) {
    this.selectedItems.splice(index, 1);
    this.selectedItemsChange.emit([...this.selectedItems]);
  }

  clearAll() {
    this.selectedItems = [];
    this.selectedItemsChange.emit(this.selectedItems);
  }

}
