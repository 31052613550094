import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HistoriqueConsultationComponent } from './historique-consultation/historique-consultation.component';

@Component({
  selector: 'app-examen-details',
  templateUrl: './examen-details.component.html',
  styleUrls: ['./examen-details.component.css']
})
export class ExamenDetailsComponent implements OnInit {

  selectedMotifs: any[] = [];

  motifs = [
    { id: 1, name: 'Douleurs musculaires et articulaires' },
    { id: 2, name: 'Fatigue générale' },
    { id: 3, name: 'Maux de tête fréquents' },
    { id: 4, name: 'Troubles du sommeil' },
    { id: 5, name: 'Infections respiratoires (rhume, grippe, bronchite)' },
    { id: 6, name: 'Problèmes digestifs (maux de ventre, ballonnements, diarrhée, constipation)' },
    { id: 7, name: 'Douleurs abdominales' },
    { id: 8, name: 'Fièvre persistante' },
    { id: 9, name: 'Toux persistante' },
    { id: 10, name: 'Douleurs thoraciques' }
  ];

  patientDetails = {
    nom: 'Baha Jemni',
    age: 22,
    dateNaissance: '',
    // dateNaissance: '22/10/1993',
  };

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  historiqueConsultationModal() {
    const modalRef = this.modalService.open(HistoriqueConsultationComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.consultationHistorique.subscribe(() => {
      console.log("Test modal done")
    })
  }

}
